import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/build/repo/web2/gatsby-starter-blog/src/components/layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "800px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/764232bc83d04f96159218d926aeba03/df56e/kinde_2.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "100%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAIAAAAC64paAAAACXBIWXMAAA7EAAAOxAGVKw4bAAACoUlEQVQ4y1VT/UtVQRC9/7xgij598rRCIYmIiCz6IQSRLMrKQlIqU/EDLVPT97z7vTszuzG796rBsOy9szNz5syZikgTKUIVSRFIABmjidEQaUSJINlbLOr2zBZNFbMjnzqShiByIh3JECoINaIiatIR6cSpNXIZUVEJi7aBQOwglIiyCQZBmHNxNX6Za9QQag7OOG3OzcEQBILwdhBcjUFgqBlLrsw1USHk1FFVnDJaymBK8UgmJdje/PLneC8lVxBmaApBAIgWo6qaDqNBlCGIlNzm59Wj/e2t9Y8HWxsQFIAIQYAXuZ1bhKGsIAjGicqZvreDlOzpr71v62urS4unhzu5Q5OSjYxIX9csA6rKN4AA7o1zK3F+crhz8HPzydzs4faPT2+WT452tbrAPIhbwbKiPJL2L7dHKPtnR2srywsP53e/bzydf3C8vy3qM/B187JNURUmMiRTZJASXPVP5roT6+9f/z7YsfoqpVAkRKgK25CZq9oJa3aAsPry69q7pRcLLx8/Wv+wAl4QGuCxK2qnVTpFkFWKrsQz226AIN4uvVp8/szKixQVoba6b/QlgmpF9j9hiDprUBPImD+9HRDIYPsZkXa2z5y3KrohrAgg5siiSgzcj1V/nR0Qz8kFX1vdj2iuy5ZdqIrob0FibSNII8696RPL1iLp4AUzSrdGlYN5majEYysDlLxevI+mYYRU09pN2yZXprIY9tqHIMpWUdkZahilJt7kuZoqJZ+SS8lmKxdXJpFd5T9fmuVLNia+pOQqa+tsV85l87V3tbMDawbe18HV3gsfhPciBMkGbAASUFWdoTvjQ8PjQ8MTw6PdkbHuyNjU6HhvbGJ6fLI31pnpTN7tTM10ujOdyXuTU/e7Pbap6dne9Gxv5h8uTWeeLgdmrQAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Kinde",
            "title": "Kinde",
            "src": "/static/764232bc83d04f96159218d926aeba03/5a190/kinde_2.png",
            "srcSet": ["/static/764232bc83d04f96159218d926aeba03/772e8/kinde_2.png 200w", "/static/764232bc83d04f96159218d926aeba03/e17e5/kinde_2.png 400w", "/static/764232bc83d04f96159218d926aeba03/5a190/kinde_2.png 800w", "/static/764232bc83d04f96159218d926aeba03/df56e/kinde_2.png 1188w"],
            "sizes": "(max-width: 800px) 100vw, 800px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span>{`
`}<span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "800px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/d311485843784650145486be90e88dec/df56e/kinde_3.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "100%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAIAAAAC64paAAAACXBIWXMAAA7EAAAOxAGVKw4bAAACBElEQVQ4y6VUyZLTMBDN/38ER4riAjfgwHbPTAIzUFBJMUzGJvEma2stLcmm7A6JY+A07+bqemq9RV70j8Ci7/uUEiK6Ed77GCPNxk/s+74b8Q8yIhpjiOa9d85xzrXS1jmlpJSSzo0x/s1fGGOmg5SS5KIsS1E0nLVCCtAghACAlNKcfKKFEKy1WmuHnhvFrn7w7R6cEVwMd9F6quhCs/c+hCClrFvGP+/crlGbA/v6YK1VWishpRgkzJYvQgje+67rYozWWHBGbQ4mZ+z6Ll9tynyvpdLBggPn3JzsvSc/jTG8bbngw/ZvWVVXmyfvt2+v89drdnXH84oxBgBT286aEVEppZVy3uk9+/Xxy+3Td9nNln34Llb39TYry7JtW2vthWZC13XOOdAAYSDnL5f5qxW/L2KhIXltjZQCEcmdI3kqA7QWWonbh+bFun62lOsdLxoN2oBRUjasIRp5dM6ZPA8YpIPyzU31fMk+/WRKSKWqshzycw4AEJHItHLesLquD1VRHA5CiLquDYBSqmmaGCMi0kKq80Cmennv6RQAoD1SiCzLjDGUPzWU1MUY6f4L7y/SQ8Q44Ng2qt2sm4g+pXiMitbSGBFHmooxUASjPWcm1fEiKpJBsu0IsocuMm3Vya2LnKfouo4sIG30nmOMU+Z/ySeEEPAPQgizJ714zG/oNwtciiB/k55bAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Kinde",
            "title": "Kinde",
            "src": "/static/d311485843784650145486be90e88dec/5a190/kinde_3.png",
            "srcSet": ["/static/d311485843784650145486be90e88dec/772e8/kinde_3.png 200w", "/static/d311485843784650145486be90e88dec/e17e5/kinde_3.png 400w", "/static/d311485843784650145486be90e88dec/5a190/kinde_3.png 800w", "/static/d311485843784650145486be90e88dec/df56e/kinde_3.png 1188w"],
            "sizes": "(max-width: 800px) 100vw, 800px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span>{`
`}<span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "800px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/cf17730cd7ba986856d076235e8aaf33/df56e/kinde_4.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "100%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAIAAAAC64paAAAACXBIWXMAAA7EAAAOxAGVKw4bAAABOUlEQVQ4y93T20rDMBgHcF9AcTB1bj3k2LRZ19kdHB5vnIoy8EmGT6ai3qpvMmj6pfUlWipOmIKODkQR/1e5yI9/yJcsFd/I0l/C2Zz8cHOWZVrrWKk0SVQUTSYTAFBKJVrnn8rfcZ7nRVEAgO96DOH6+kbLkwc7u8SyqY0C6Sc6mW37Gqso4pggwzzc27+5ur4YjWrVNWxagrI4jktwrJR0RHW18vTweH97V1lecRnHpiUdAQBlzUq5jJu1+snR8HI87ocdu2EQy37F8QJYUEYs26htUhuNzs5Ph8cMYY875c3xFAvKAtn0HCEdsd3pMoSlcBc4dhRRyxaE9rbCQbc36PXDVoAahiC0/MI0QLvpc0w8R/iu9LjwuMMxCYN2kswf1eyRpGmqpwEADfC2eE7Tj+xffYzfwy+RKQQt7YumuwAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Kinde",
            "title": "Kinde",
            "src": "/static/cf17730cd7ba986856d076235e8aaf33/5a190/kinde_4.png",
            "srcSet": ["/static/cf17730cd7ba986856d076235e8aaf33/772e8/kinde_4.png 200w", "/static/cf17730cd7ba986856d076235e8aaf33/e17e5/kinde_4.png 400w", "/static/cf17730cd7ba986856d076235e8aaf33/5a190/kinde_4.png 800w", "/static/cf17730cd7ba986856d076235e8aaf33/df56e/kinde_4.png 1188w"],
            "sizes": "(max-width: 800px) 100vw, 800px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span>{`
`}<span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "800px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/c56db9a9fdf7912b94d6c42e909e213b/df56e/kinde_5.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "100%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAIAAAAC64paAAAACXBIWXMAAA7EAAAOxAGVKw4bAAABfklEQVQ4y2P4TwFgoLLm33/+/Pr9Gw39/vOHxjb/+/evb9LErPy8orKywtLSwtLSnMKCjNycSVOn4tP879+///////37NzAiXFJRQUVHS1lLU05VxSvAX11PNyAsFFkZds1//vzJyM1x9fZycHM1tbHWNzdLzsxw8nCPS0kmrPnv378xiQk6xkZGlhY6xkYQhqGleWxSEhGa//1NycwwtrRw8vRw8/Vx9/Vx9vQwsrRIz83BpxkOXr958+z5sxcvXz55+vTxkyfPX7x4+uzZ6zdviAqw6pqawOCQkLCwrOzsvIKC0PDwwKDg2vp6mDK8mn38/fkEhfQMDWfOmqWjbyAhLSMsJu4XEEhUgIVHRiooK+sbGoWFRzo6O+sbGikoq0TFxBCl2T8wSExSUlVDU0VN3d7RSUffQFRcIjg0lCjNSSmpBkZGNnb2Lm7uDk7O1rZ2BkbGqenpRIX2x48f34LBmzdv3rx9++7du7dv33769In2WfIvDkB7m4nXDABwFPxjU7OG0AAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Kinde",
            "title": "Kinde",
            "src": "/static/c56db9a9fdf7912b94d6c42e909e213b/5a190/kinde_5.png",
            "srcSet": ["/static/c56db9a9fdf7912b94d6c42e909e213b/772e8/kinde_5.png 200w", "/static/c56db9a9fdf7912b94d6c42e909e213b/e17e5/kinde_5.png 400w", "/static/c56db9a9fdf7912b94d6c42e909e213b/5a190/kinde_5.png 800w", "/static/c56db9a9fdf7912b94d6c42e909e213b/df56e/kinde_5.png 1188w"],
            "sizes": "(max-width: 800px) 100vw, 800px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      